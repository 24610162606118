import { DefaultConfig } from './types';
import { defaultConfig } from './default';

export const defaultJuniper: DefaultConfig = {
  ...defaultConfig,
  brand: 'juniper',
  healthCoachCreation: true,
  showDeliveryInstructions: true,
  defaultTimezone: 'Australia/Sydney',
  syncConsultationsEnabled: true,
  medicareEnabled: true,
  lgaEnabled: true,
  plugsEnabled: true,
  shopEnabled: true,
};
