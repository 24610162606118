import { defaultJuniper } from './default-juniper';
import { sharedDevAuEnv } from './shared';
import { Config } from './types';

export const config: Config = {
  ...defaultJuniper,
  ...sharedDevAuEnv,
  environment: 'development',
  apiUrl: 'https://api.admin.au.dev.eucalyptus.vc',
  restApiUrl: 'https://dev.admin-api.myjuniper.com',
  assetsUrl: 'https://assets.myjuniper.com',
  logoUrl: 'https://assets.myjuniper.com/juniper-logo.svg',
  shopifyHostname: 'dev-myjuniper.myshopify.com',
  userAppUrl: 'https://dev.app.myjuniper.com',
  doctorsAppUrl: 'https://dev.doctors.myjuniper.com',
  googleOAuthClientId:
    '966685249037-0ikbs91jfl2gns80jlrsai618ak8smp9.apps.googleusercontent.com',
  quizAdminUrl: 'https://dev.quiz-admin.myjuniper.com',
  launchDarklyClientId: '6062ab38c8369f0e6d6c5ee4',
  healthCoachingFlows: true,
};
